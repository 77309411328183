<script>
  import { onMount } from 'svelte';
  import { register } from 'swiper/element/bundle';

  // register Swiper custom elements
  register();

  $: testimonials = [];

  // Fisher-Yates shuffle algorithm
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  onMount(() => {
    // Get data from HTML file in AEM
    const allTestimonialsFromHtml = document.querySelectorAll(
      "[data-application='recommendations'] .feature-topic"
    );

    allTestimonialsFromHtml.forEach((testimonial) => {
      let testimonialObject = {
        imgSrc: testimonial.children[0].src,
        imgAlt: testimonial.children[0].alt,
        name: testimonial.children[1].textContent,
        job: testimonial.children[2].textContent,
        quote: testimonial.children[3].children[0].children[0].textContent,
      };
      testimonials.push(testimonialObject);
    });

    testimonials = shuffleArray(testimonials);
  });
</script>

{#if testimonials}
  <swiper-container
    autoplay-delay={5000}
    autoplay-disable-on-interaction={true}
    navigation={true}
    pagination={true}
    space-between={10}
  >
    {#each testimonials as testimonial}
      <swiper-slide>
        <div class="feature-topics testimonials highlight">
          <div class="feature-topic">
            <img
              class="portrait"
              src={testimonial.imgSrc}
              alt={testimonial.imgAlt}
              loading="lazy"
            />
            <h3>{testimonial.name}</h3>
            <p class="jobtitle">{testimonial.job}</p>
            <blockquote>
              <p>
                <q>{testimonial.quote}</q>
              </p>
            </blockquote>
          </div>
        </div>
      </swiper-slide>
    {/each}
  </swiper-container>
{/if}
